import { toPng } from 'html-to-image'; // Generates PNG from HTML node
import FileSaver from 'file-saver'; // Serves generated PNG as download

import d3 from './d3';
import removeExcessCharacters from './removeExcessCharacters';

const saveChartAsImage = function (selector, linkSelector, averageCharacterWidth, addTitle = true) {
  // Update UI
  $(linkSelector).text('Generating image...');

  // Add inline styling to chart
  var $originalSVG = $(selector + ' svg');

  // Add chart options as an annotation
  var $chart = $originalSVG.clone();
  $chart.attr('xmlns', 'http://www.w3.org/2000/svg');
  $chart.attr('xmlns:xlink', 'http://www.w3.org/1999/xlink');
  $chart.attr('height', parseInt($chart.attr('height')) + 20); // Add padding at the end so the chart isn't cut off
  $chart.css('background-color', $('body').css('background-color'));
  var opts = $(selector).data('options');
  addAnnotationsToChart($chart, opts);

  // Append chart to document and generate PNG
  $('body').append(
    $('<div>').attr('id', 'temp-chart-with-annotations').css('width', 'fit-content').append($chart),
  );
  const chartNode = document.getElementById('temp-chart-with-annotations');

  // Get chart resolution and scale up
  const targetHeight = 2160;
  const targetWidth = (targetHeight / chartNode.offsetHeight) * chartNode.offsetWidth;

  // Add a delay for any inserted images to load
  setTimeout(function () {
    toPng(chartNode, {
      canvasWidth: targetWidth,
      canvasHeight: targetHeight,
    }).then(function (dataUrl) {
      FileSaver.saveAs(dataUrl, 'chart.png');
      // Remove appended chart and update UI
      $('#temp-chart-with-annotations').remove();
      $(linkSelector).text('Download as Image');
    });
  }, 100);

  function addAnnotationsToChart($chart, opts) {
    var d3Chart = d3.select($chart.get(0));
    var heightChange = 0;
    if (addTitle && opts.hasTitle()) {
      d3Chart
        .append('text')
        .attr('x', 10)
        .attr('y', 20)
        .text(opts.getTitle())
        .style('font-size', '14px')
        .style('font-weight', 'bold')
        .attr('class', 'chart-title');
      heightChange += 20;
    }
    var numSettings = opts.settingKeys().length;
    if (numSettings) {
      var settingsContainer = d3Chart.append('g').attr('class', 'settings');
      var longestKeyText = 0;
      var longestValText = 0;
      var wrappedRows = 0;
      var charactersPerRowInValue = Math.floor(400 / averageCharacterWidth);
      for (var i = 0; i < numSettings; i++) {
        var key = removeExcessCharacters(opts.settingKeys()[i]);
        var val = removeExcessCharacters(opts.getSettings()[key]);
        key += ':';
        var valueRows = [val];
        // Limit width of values to 400 pixels
        while (valueRows.slice(-1)[0].length > charactersPerRowInValue) {
          var row = valueRows.pop();
          var splitPos = row.lastIndexOf(' ', charactersPerRowInValue);
          valueRows.push(row.slice(0, splitPos));
          valueRows.push(row.slice(splitPos + 1));
        }
        longestKeyText = key.length > longestKeyText ? key.length : longestKeyText;
        settingsContainer
          .append('text')
          .text(key)
          .attr('x', opts.hasTitle() ? 30 : 10)
          .attr('y', opts.hasTitle() ? 40 : 20)
          .attr('dy', 1.7 * i + 1.2 * wrappedRows + 'em');
        valueRows.forEach(function (valRow, idx) {
          if (idx == 0) {
            heightChange += 22;
          } else {
            wrappedRows++;
            heightChange += 19;
          }
          longestValText = valRow.length > longestValText ? valRow.length : longestValText;
          settingsContainer
            .append('text')
            .text(valRow)
            .attr('x', opts.hasTitle() ? 20 : 0)
            .attr('y', opts.hasTitle() ? 40 : 20)
            .attr('dy', 1.7 * i + 1.2 * wrappedRows + 'em')
            .attr('class', 'key-value');
        });
      }
      $(settingsContainer.nodes()[0])
        .find('text.key-value')
        .each(function () {
          $(this).attr(
            'x',
            parseInt($(this).attr('x')) + longestKeyText * averageCharacterWidth + 20,
          );
        });
    }
    alterTransformTranslateY($chart.find('g.chart-wrapper'), heightChange);

    var currentHeight = parseInt($chart.css('height'));
    if (currentHeight == 0) {
      currentHeight = parseInt($chart.attr('height'));
    }

    if (opts.hasFooter()) {
      $.each(opts.getFooter().split(/<br ?\/?>/), function () {
        heightChange += 15;
        d3Chart
          .append('text')
          .text(this)
          .attr('x', 10)
          .attr('y', currentHeight + heightChange);
      });
      heightChange += 16;
    }

    var newHeight = currentHeight + heightChange;

    $chart.css('height', newHeight);
    $chart.attr('height', newHeight);

    var currentWidth = parseInt($chart.css('width')),
      requiredWidth = (longestKeyText + longestValText) * averageCharacterWidth + 30;
    if (currentWidth == 0) {
      currentWidth = parseInt($chart.attr('width'));
    }
    if (currentWidth < requiredWidth) {
      $chart.css('width', requiredWidth);
      $chart.attr('width', requiredWidth);
    }
  }

  function alterTransformTranslateY($ele, amt) {
    var tform = $ele.attr('transform');
    var pts = tform.split('translate(');
    var newTForm = pts[0] + 'translate(';
    var tFormX = pts[1].split(/[, ]/)[0];
    var tFormY = pts[1].split(/[, ]/)[1].split(')')[0];
    var after = pts[1].split(')').slice(1).join(')');

    newTForm += tFormX + ',';
    tFormY = parseInt(tFormY) + amt;

    newTForm += tFormY + ')' + after;
    $ele.attr('transform', newTForm);
  }
};

export default saveChartAsImage;
