/* eslint indent: 0 */
import d3 from './d3';
import BaseChart from './BaseChart';
import isWkHTMLToPDF from './isWkHTMLToPDF';
import getPageWidth from './getPageWidth';

/**
 * wrapper: valid d3 selector
 * dataSeries: An array of charting.series objects
 * colorSeries: A charting.series object for determining colors
 * options: A charting.Options object
 */
function BreakdownChart(wrapper, dataSeries, colorSeries, options, averageCharacterWidth) {
  var self = this;
  self.outerHeight = 900;
  self.outerWidth = getPageWidth(950, 950) - 24;

  BaseChart.apply(self, [wrapper, 1, options, averageCharacterWidth]);
  self.margin.left = 10;

  const offset = 8;
  const noDataColor = '#1a1a1a1a';
  const minBarHeight = 12;

  self.draw = function () {
    // Transform the data into a format that is easier to work with
    let data = [];
    dataSeries[0].data.forEach((d, i) => {
      const row = {
        label: dataSeries[0].labels[i],
        total: dataSeries.reduce((acc, series) => acc + series.data[i], 0),
      };
      dataSeries.forEach(series => {
        row[series.name] = series.data[i];
      });
      data.push(row);
    });

    // Order from least to most
    data = data.sort((a, b) => {
      const aTotal = a.total;
      const bTotal = b.total;
      if (aTotal < bTotal) return -1;
      if (aTotal > bTotal) return 1;
      return 0;
    });

    const grandTotal = data.reduce((acc, d) => acc + d.total, 0);
    const labelWidth = d3.max(data, d => d.label.length) * averageCharacterWidth;
    const chartWidth = self.width - labelWidth;
    const barWidth = chartWidth / 3;

    // Map the colors to the series names for coloring the bars
    const colorMap = {};
    colorSeries.data.forEach((d, i) => {
      colorMap[colorSeries.labels[i]] = d;
    });
    const barOrder = colorSeries.labels;

    var svg = self.createBaseChart();

    // Create Legend
    const legendHeight = 70;
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('width', self.width)
      .attr('height', legendHeight);

    // Title
    legend
      .append('text')
      .attr('x', 0)
      .attr('y', offset)
      .style('font-size', '18px')
      .style('font-weight', 'bold')
      .text(options.getTitle());

    let legendX = 10;
    let legendY = 20;
    const legendCharacterWidth = 10;

    // Loop over yGroups and add to legend
    barOrder.forEach(key => {
      // Add shape
      legend
        .append('rect')
        .attr('y', legendY)
        .attr('x', legendX)
        .attr('width', 16)
        .attr('height', 16)
        .style('fill', colorMap[key]);
      // Add text
      legend
        .append('text')
        .text(key)
        .attr('x', legendX + 20)
        .attr('y', legendY + 10)
        .attr('line-height', 24)
        .attr('font-size', 16)
        .attr('alignment-baseline', 'middle');
      legendX += key.length * legendCharacterWidth + 40;
    });

    // Set the ranges for x and y axes
    const xScale = d3.scaleLinear().range([0, barWidth]);
    const yScale = d3.scaleLinear().range([0, self.height]);
    const dataLabelWidth = 42; // As a percentage of the bar width

    const getRowHeight = row => {
      const rowTotal = row.total;
      if (rowTotal < minBarHeightScaled) return minBarHeight;
      return yScale(rowTotal);
    };

    // Domain for the xScale
    xScale.domain([0, 100]);

    // Domain for the yScale
    yScale.domain([0, grandTotal]);

    // Recalculate total again, but this time giving each row an offset and a minimum height. This lets the bars scale while having a fixed minimum height and spacing.
    const minBarHeightScaled = yScale.invert(minBarHeight);
    const offsetScaled = yScale.invert(offset);
    const newTotal = data.reduce((acc, d) => {
      // Account for the offset
      acc += offsetScaled;
      const rowTotal = d.total;
      if (rowTotal < minBarHeightScaled) return acc + minBarHeightScaled;
      return acc + rowTotal;
    }, 0);

    yScale.domain([0, newTotal]);

    // Shifted base to make room for labels
    const chartBase = svg
      .append('g')
      .attr('transform', `translate(${labelWidth},${legendHeight})`)
      .attr('height', self.height)
      .attr('width', chartWidth);
    // Append the rectangles for the bar chart
    let tot = offset;
    const barGroups = chartBase
      .selectAll('.bar-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .attr('transform', (d, i) => {
        let oldTot = tot;
        tot += getRowHeight(d);
        return `translate(${offset},${oldTot + offset * i})`;
      });

    barGroups.each(function (d) {
      const rowTotal = d.total;
      const group = d3.select(this);
      const totalHeight = getRowHeight(d);

      const rowPercentage = ((rowTotal * 100) / grandTotal).toFixed(1);

      // Add a label for the row
      group
        .append('text')
        .attr('x', rowPercentage >= 10 ? -55 : -45)
        .attr('y', totalHeight / 2)
        .attr('dy', '.35em')
        .attr('dx', -3)
        .style('font-size', '14px')
        .style('text-anchor', 'end')
        .text(d => d.label);

      // Add row percentage
      group
        .append('text')
        .attr('x', -10)
        .attr('y', totalHeight / 2)
        .attr('dy', '.35em')
        .attr('dx', -3)
        .style('text-anchor', 'end')
        .text(() => rowPercentage + '%');

      // Loop over each data point and add a bar and label for each
      let offset = 0;
      let labelOffset = 0;
      barOrder.forEach((key, i) => {
        // Add bars for each data point
        if (rowTotal !== 0) {
          const percent = (d[key] / rowTotal) * 100;
          group
            .append('rect')
            .attr('class', 'bar')
            .attr('x', xScale(offset))
            .attr('width', xScale(percent))
            .attr('height', totalHeight)
            .attr('fill', colorMap[key]);

          // Add a label for each data point
          group
            .append('text')
            .attr('x', xScale(110 + labelOffset))
            .attr('y', totalHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', 3)
            .style('text-anchor', 'start')
            .style('fill', '#4f4f4f')
            .style('font-size', '14px')
            .text(d => `${((d[key] * 100) / rowTotal).toFixed(1)}% ${key}`);
          // Percentage of total %
          group
            .append('text')
            .attr('x', xScale(120 + dataLabelWidth * 2 + labelOffset))
            .attr('y', totalHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', 3)
            .style('text-anchor', 'start')
            .style('fill', '#4f4f4f')
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .text(d => `${((d[key] * 100) / grandTotal).toFixed(1)}%`);
          // Percentage of total label
          group
            .append('text')
            .attr(
              'x',
              d =>
                xScale(120 + dataLabelWidth * 2 + labelOffset) +
                ((d[key] * 100) / grandTotal > 10 ? 42 : 35),
            )
            .attr('y', totalHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', 3)
            .style('text-anchor', 'start')
            .style('fill', '#4f4f4f')
            .style('font-size', '14px')
            .text(() => key);
          offset += percent;
        } else {
          if (i === 0) {
            group
              .append('rect')
              .attr('class', 'bar')
              .attr('x', xScale(offset))
              .attr('width', xScale(100))
              .attr('height', totalHeight)
              .attr('fill', noDataColor);
          }

          // Add a label for each data point
          group
            .append('text')
            .attr('x', xScale(110 + labelOffset))
            .attr('y', totalHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', 3)
            .style('text-anchor', 'start')
            .style('fill', '#1a1a1ab3')
            .style('font-size', '14px')
            .text(() => '-');
          group
            .append('text')
            .attr('x', xScale(110 + dataLabelWidth * 2 + labelOffset))
            .attr('y', totalHeight / 2)
            .attr('dy', '.35em')
            .attr('dx', 3)
            .style('text-anchor', 'start')
            .style('fill', '#1a1a1ab3')
            .style('font-size', '14px')
            .text(() => '-');
        }
        labelOffset += dataLabelWidth + 10;
      });
    });

    // Left axis label
    chartBase
      .append('text')
      .attr('x', 0)
      .attr('y', -offset)
      .style('font-size', '14px')
      .style('text-anchor', 'end')
      .style('fill', '#4f4f4f')
      .text(options.getYAxisLabel());

    // Global distribution label
    chartBase
      .append('text')
      .attr('x', xScale(205) + offset / 2)
      .attr('y', -offset)
      .style('font-size', '14px')
      .style('text-anchor', 'start')
      .style('fill', '#4f4f4f')
      .text('Percentage of all');
    const shadingYOffset = 25;
    chartBase
      .append('rect')
      .attr('class', 'bar')
      .attr('x', xScale(205) + offset / 4)
      .attr('y', -shadingYOffset)
      .attr('width', xScale(95) - offset / 4)
      .attr('height', self.height + shadingYOffset)
      .attr('fill', '#4f4f4f15');

    // Relative distribution label
    chartBase
      .append('text')
      .attr('x', xScale(110) + offset)
      .attr('y', -offset)
      .style('font-size', '14px')
      .style('text-anchor', 'start')
      .style('fill', '#4f4f4f')
      .text(`${options.getXAxisLabel()} distribution`);

    // y axis
    chartBase
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', self.height + offset)
      .style('stroke-width', 1)
      .attr('stroke', '#1a1a1a33');

    // x axis
    chartBase
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', barWidth + offset)
      .attr('y2', 0)
      .style('stroke-width', 1)
      .attr('stroke', '#1a1a1a33');

    // 50% divider
    chartBase
      .append('line')
      .attr('x1', xScale(50))
      .attr('y1', 0)
      .attr('x2', xScale(50))
      .attr('y2', self.height + offset)
      .style('stroke-dasharray', '5,5') //dashed array for line
      .attr('transform', `translate(${offset},0)`)
      .attr('stroke', '#1a1a1a33');

    if (options.hasFooterImage()) {
      const footerImage = svg
        .append('svg:image')
        .attr('xlink:href', options.getFooterImage())
        .attr('height', 20)
        .attr('x', 10)
        .attr('y', self.height + self.margin.bottom);
      // Reposition once image is loaded to the proper width
      $(() => {
        const footerImageWidth = footerImage.style('width').replace('px', '');
        footerImage.attr('x', self.width - footerImageWidth);
      });
    }

    if (!isWkHTMLToPDF() && options.getAllowDownload()) {
      this.addDownloadLink(wrapper, averageCharacterWidth);
    }
  };
}
export default BreakdownChart;
